

































import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';
import FleetFilterService, { FleetFilterServiceS } from '@/modules/cars/modules/fleet/fleet-filter.service';
import CarsService, { CarsServiceS } from '@/modules/cars/cars.service';
import CustomMultiSelect from '@/modules/common/components/ui-kit/custom-multi-select.vue';
import VEHICLE_TYPE_FILTER from '@/modules/cars/constants/vehicle-type-filter.constant';
import { Popover } from 'element-ui';

@Component({
    components: {
        CustomMultiSelect,
        'el-popover': Popover,
    },
})
export default class CarClassesFilter extends Vue {
    @Inject(CarsFiltersServiceS) private carsFiltersService!: CarsFiltersService;
    @Inject(CarsServiceS) private carsService!: CarsService;
    @Inject(FleetFilterServiceS) private fleetFiltersService!: FleetFilterService;
    disabled = false;

    get items() {
        this.carsService.storeState.settings.currentDocumentCarClasses = this.carsFiltersService.carClassFilter;
        const documentCarClass = new Set(this.carsService.storeState.settings.currentDocumentCarClasses);
        return this.carsFiltersService.allCarClasses
            .filter(val => documentCarClass.has(val) || this.carsService.storeState.settings.isAvgPrice)
            .map(val => ({ value: val, name: val }))
            .sort((a, b) => {
                if (a.value < b.value) {
                    return -1;
                }
                return 1;
            });
    }

    get currentValue() {
        if (!this.carsService.storeState.settings.carClasses) {
            return null;
        }

        const documentCarClass = new Set(this.carsFiltersService.carClassFilter);
        const allowedItems = this.items.map(({ value }) => value);

        this.carsService.storeState.settings.carClasses = this.carsService.storeState.settings.carClasses
            .filter(val => allowedItems.includes(val));
        return this.carsService.storeState.settings.carClasses.map(val => ({
            value: val,
            name: val,
            disabled: !documentCarClass.has(val) && !this.carsService.storeState.settings.isAvgPrice,
        }));
    }

    set currentValue(value) {
        if (value) {
            this.carsService.storeState.settings.carClassesPreserve = true;
            this.fleetFiltersService.saveCarClasses(value.map(item => item.value));
            this.carsService.reloadDocument();
        }
    }

    get currentFilterType() {
        const { filter } = this.carsService.storeState.settings.vehicleType;
        switch (filter) {
            case VEHICLE_TYPE_FILTER.INCLUDE:
                return 'Include';
            case VEHICLE_TYPE_FILTER.WITHOUT:
                return 'Without';
            case VEHICLE_TYPE_FILTER.ONLY:
                return 'Only';
            default:
                return 'Without';
        }
    }

    get options() {
        return [
            {
                label: 'Include',
                value: VEHICLE_TYPE_FILTER.INCLUDE,
            },
            {
                label: 'Only',
                value: VEHICLE_TYPE_FILTER.ONLY,
            },
            {
                label: 'Without',
                value: VEHICLE_TYPE_FILTER.WITHOUT,
            },
        ];
    }

    filterByVehicle(vehicleTypeFilter: VEHICLE_TYPE_FILTER) {
        this.disabled = true;
        this.carsService.storeState.settings.vehicleType.filter = vehicleTypeFilter;
    }

    get isAvgPrice() {
        return this.carsService.storeState.settings.isAvgPrice;
    }
}
